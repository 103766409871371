<template>
	<div class="reset-password">
		<TransitionGroup name="fade" appear>
			<h3 :key="1">{{ translate('login.forgot.title') }}</h3>
			<p :key="2" class="subtitle">{{ translate('login.forgot.subTitle') }}</p>
		</TransitionGroup>

		<Transition name="fade-slide-up" appear>
			<form @submit.prevent="submit">
				<div
					class="form-input"
					:class="{ error: error.type === 'email' }"
					:data-error="error.type === 'email' ? error.message : ''"
				>
					<label for="email">{{ translate('emailAddress') }}</label>
					<Icon name="email" width="18" />
					<input id="email" v-model="email" type="email" placeholder="john.doe@email.com" />
				</div>

				<div v-if="error.type === 'other'" class="error-panel">
					<Icon name="alert-triangle" class="red" width="18" />
					<p>{{ error.message }}</p>
				</div>

				<button v-if="!resetEmailSent && !loading" type="submit" class="icon-right">
					{{ translate('login.forgot.sendPasswordResetEmail')
					}}<Icon name="arrow-forward" class="white" width="22" />
				</button>
				<button v-if="!resetEmailSent && loading" type="button" disabled="true">
					<Loader :size="27" :border-width="3" border-color="white" />
				</button>
				<button v-if="resetEmailSent" type="button" disabled="true" class="green icon-left">
					<Icon name="checkmark" class="white" width="22" />{{
						translate('login.forgot.resetEmailSent')
					}}
				</button>
				<p class="no-account">
					<router-link to="/login">{{ translate('login.forgot.goBackToLogin') }}</router-link>
				</p>
			</form>
		</Transition>
	</div>
</template>

<script>
import { auth } from '@/lib/supabase'
import { handleError } from '@/utils/utils'
import Loader from '@/components/Loader'
import Icon from '../components/Icon.vue'

export default {
	components: {
		Loader,
		Icon,
	},

	data() {
		return {
			email: '',
			loading: false,
			resetEmailSent: false,
			error: {
				type: '',
				message: '',
			},
		}
	},

	methods: {
		async submit() {
			this.error = {
				type: '',
				message: '',
			}

			if (!this.email) {
				this.handleFormError({
					code: 'no-email',
					message: this.translate('login.forgot.error.emailRequired'),
				})
				return
			}

			this.loading = true

			const { error } = await auth.api.resetPasswordForEmail(this.email)

			if (error) {
				this.loading = false
				handleError(error)
				this.handleFormError(error)
			} else {
				this.loading = false
				this.resetEmailSent = true
			}
		},

		handleFormError(error) {
			switch (error.status) {
				// TODO: Lookup Supabase error codes
				case 404:
					this.error.type = 'email'
					this.error.message = this.translate('login.forgot.error.userDoesNotExist')
					break

				default:
					this.error.type = 'other'
					this.error.message = error.message
					break
			}
		},
	},
}
</script>

<style lang="scss">
.reset-password {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;

	form {
		margin: 50px auto 0;
		max-width: 350px;
		width: 90%;
	}

	.form-input {
		position: relative;

		+ .form-input {
			margin-top: 25px;
		}

		&.error {
			border-color: $red;
			text-align: left;

			input {
				border-color: $red;
			}

			&::after {
				bottom: 0;
				color: $red;
				content: attr(data-error);
				font-size: 12px;
				font-weight: 600;
				left: 4px;
				position: absolute;
				transform: translateY(120%);
			}
		}
	}

	.error-panel {
		align-items: flex-start;
		background: rgba($red, 0.05);
		border: 2px solid rgba($red, 0.25);
		border-radius: $border-radius;
		color: $red;
		display: flex;
		font-size: 14px;
		font-weight: 600;
		margin-top: 25px;
		padding: 10px;
		text-align: left;

		.icon i {
			margin-right: 5px;
			top: 2px;
		}

		p {
			position: relative;
			top: 2px;
		}
	}

	button {
		justify-content: center;
		margin-top: 35px;
		width: 100%;

		&.disabled {
			pointer-events: none;
		}
	}

	.no-account {
		margin-top: 10px;
	}
}
</style>
